export const API_URL = {
  patientBan: 'patients/ban',
  patientUnban: 'patients/unban',
  patientIsTest: 'patients/update-is-test',

  promoCodeCheck: 'promocodes/check-admin',

  sessionBrone: 'session-payment/book_by_admin',

  therapistsList: 'therapists/search/with_filter',
  therapistBan: 'therapists/ban',
  therapistUnban: 'therapists/unban',
  therapistApprove: 'therapists/approve',
  therapistUnapprove: 'therapists/unapprove',
  therapistIsTop: 'therapists/update-is-top',
  therapistIsTest: 'therapists/update-is-test',
  therapistIsAdult: 'therapists/update-is-adult',
  therapistIsChild: 'therapists/update-is-child-therapist',
  therapistIsSupervisor: 'therapists/update-is-supervisor',
  therapistIsMedsi: 'therapists/update-is-medsi',
  therapistsExport: 'therapists/statistics',

  therapistsRequestVerify: (therapistId: string) =>
    `/therapists/requests/${therapistId}/verify`,
  therapistsRequestReject: (therapistId: string) =>
    `/therapists/requests/${therapistId}/reject`,

  therapistProfileUpdateAccept: (id: string) =>
    `/therapists/profile-updates/${id}/accept`,
  therapistProfileUpdateReject: (id: string) =>
    `/therapists/profile-updates/${id}/reject`,

  therapistById: (id: string) => `/therapists/${id}`,

  diseases: '/diseases',
  diseasesById: (id: string) => `/diseases/${id}`,
  diseasesGroup: '/diseases/group',
  diseasesGroupById: (id: string) => `/diseases/group/${id}`,

  faqGroup: '/faq/group',

  companyStatistic: (companyId: string, year: number) =>
    `/b2b/companies/${companyId}/statistics?year=${year}`,
  companyPromocodes: (companyId: string) =>
    `/b2b/companies/${companyId}/promocodes`,
  editCompany: (companyId: string) => `/b2b/companies/${companyId}`,
  createCompany: '/b2b/companies/',
  archiveCompany: (companyId: string) => `/b2b/companies/${companyId}/archive`,
  unarchiveCompany: (companyId: string) =>
    `/b2b/companies/${companyId}/unarchive`,
  addPromocodes: (companyId: string) =>
    `/b2b/companies/${companyId}/promocodes`,

  getPublicAssignedUrl: '/get-public-signed-url',

  statisticsSessions: '/statistics/sessions',
  statisticsTherapistSessions: (therapistId: string) =>
    `/statistics/sessions/therapist/${therapistId}`,
  therapistSessions: '/sessions',
  createReuseablePromocode: '/promocodes',
  updateReuseablePromocode: (id: string) => `/promocodes/${id}`,
  cancelSessionPayment: 'sessions',
  setSessionPaymentPaid: 'sessions',
  promotedSessionStatistics: '/promote',
  apiKey: '/api-keys',
  searchStatistics: '/search-statistics',
  clientsStatistics: 'statistics/sessions-clients',
  therapistUpdateRewardInPercent: 'therapists/update-reward-in-percent',
  therapistUpdateSessionPrice: 'therapists/update-session-price',
  therapistUpdateShortAbout: 'therapists/update-short-about',
  therapistUpdateAbout: 'therapists/update-about',
  currencies: '/payment-gateway/currency',
  updateCurrency: '/payment-gateway/currency',

  createPaymentLink: '/payment-link',
  fetchPaymentLink: '/payment-link',
};
